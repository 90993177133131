import { ActionTypes } from 'constants@app'
import { ISettingsAction } from 'actions@app/settings'
import { IUser } from 'model@app/user'

export interface ISettingsReducer {
  user?: IUser
}

const settings = (state: ISettingsReducer = {}, action: ISettingsAction) => {
  switch (action.type) {
    case ActionTypes.EDIT_USER_INFO: {
      return {
        ...state,
        user: action.payload.userInfo,
      }
    }

    default: {
      return state
    }
  }
}

export default settings
