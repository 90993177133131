export enum ServiceNameType {
  MINA = 'MINA',
  HAPPYLOGUE = 'HAPPYLOGUE',
}

export enum RenderLayoutType {
  PDF = 'PDF',
  PREVIEW = 'PREVIEW',
}

export enum CreateBookStepStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export enum GrantPageType {
  OWN_TIMELINE = 'OWN_TIMELINE',
  OWN_ALBUM = 'OWN_ALBUM',
  PAGE_TIMELINE = 'PAGE_TIMELINE',
  PAGE_ALBUM = 'PAGE_ALBUM',
  LOGIN = 'LOGIN',
}

export enum PrintLogsType {
  REQUEST = 'REQUEST',
  RESPONSE = 'RESPONSE',
  RESULT = 'RESULT',
  SHIPPING = 'SHIPPING',
}

export enum StatusType {
  SHIPPED = 'SHIPPED',
  PENDING_PRINT = 'PENDING_PRINT',
  CANCELLED = 'CANCELLED',
  UNSHIPPED = 'UNSHIPPED',
  COMPLETED = 'COMPLETED',
}

export enum PrintStatusType {
  PENDING = 'PENDING',
  PRINT_RECEPTION = 'PRINT_RECEPTION',
  PRINT_RESULT = 'PRINT_RESULT',
  COMPLETED = 'COMPLETED',
  PRINT_RESULT_FAILED = 'PRINT_RESULT_FAILED',
}

export enum ProductStatusType {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  COMPLETE = 'COMPLETE',
  EXPIRED = 'EXPIRED',
}

export enum CartStatusType {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  PAID = 'PAID',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
}

export enum ServiceProviderType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export enum CouponType {
  AMOUNT_OFF = 'amountOff',
  PERCENT_OFF = 'percentOff',
}

export enum PaymentType {
  STRIPE = 'STRIPE',
}

export enum CartType {
  HAPPYLOGUE_PRINT = 'HAPPYLOGUE_PRINT',
}

export enum ShippingPriorityType {
  DEFAULT = 'DEFAULT',
}

export enum ServiceClientPathType {
  HAPPYLOGUE = '/happylogue',
  MINA = '/mina',
}

export enum ServiceApiPathType {
  HAPPYLOGUE = '/v1/happylogue',
  MINA = '/v1/mina',
}

export enum PageType {
  PHOTO_PAGE = 'PHOTO_PAGE',
  COVER_PAGE = 'COVER_PAGE',
}

export enum BookLayoutTypes {
  DOUBLE_SIDED = 'DOUBLE_SIDED',
  SINGLE_SIDED = 'SINGLE_SIDED',
}

export enum CoverType {
  FULL_COVER = 'FULL_COVER',
  FRONT_COVER = 'FRONT_COVER',
}

export enum BookSourceTypes {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
}

export enum BookContentTypes {
  TIMELINE = 'TIMELINE',
  ALBUM = 'ALBUM',
}

export enum BookContentOwnerTypes {
  OWN = 'OWN',
  PAGE = 'PAGE',
}

export enum PdfConvertionType {
  CONVERTED = 'CONVERTED',
  NOT_CONVERTED = 'NOT_CONVERTED',
}

export enum CoverConvertionType {
  CONVERTED = 'CONVERTED',
  NOT_CONVERTED = 'NOT_CONVERTED',
}

export enum BookEditType {
  ADD_FEED = 'ADD_FEED',
  UPDATE_TITLE = 'UPDATE_TITLE',
  UPDATE_COVER = 'UPDATE_COVER',
  UPDATE_FEED_IMAGE = 'UPDATE_FEED_IMAGE',
  UPDATE_FEED_MESSAGE_AND_DATE = 'UPDATE_FEED_MESSAGE_AND_DATE',
  UPDATE_FEED_ALBUM_TITLE_AND_CAPTION = 'UPDATE_FEED_ALBUM_TITLE_AND_CAPTION',
  DELETE_ALBUM_FEED = 'DELETE_ALBUM_FEED',
  DELETE_FEED = 'DELETE_FEED',
  DELETE_BOOK = 'DELETE_BOOK',
}

export enum CouponEditType {
  ADD_COUPON = 'ADD_COUPON',
  DELETE_COUPON = 'DELETE_COUPON',
}

export enum LoadingStatusType {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED_MINIMUM_PAGE = 'FAILED_MINIMUM_PAGE',
  FAILED_MAXIMUM_PAGE = 'FAILED_MAXIMUM_PAGE',
}

export enum PopUpStatusType {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export enum SingleSidedMessageTruncateLength {
  HAPPYLOGUE = 300,
  MINA = 270,
}

export enum DoubleSidedMessageTruncateLength {
  HAPPYLOGUE = 850,
  MINA = 550,
}

export enum SplitPdfStatusType {
  NOT_STARTED = 'NOT_STARTED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}
