import { ActionTypes } from 'constants@app'
import { IPaymentAndPriceSettingsAction } from 'actions@app/paymentAndPriceSettings'
import { IPaymentAndPriceSettings } from 'model@app/paymentAndPriceSettings'

export interface IPaymentAndPriceSettingsReducer {
  paymentAndPriceSettings?: IPaymentAndPriceSettings
}

const initialState: IPaymentAndPriceSettingsReducer = {}

const paymentAndPriceSettings = (
  state: IPaymentAndPriceSettingsReducer = initialState,
  action: IPaymentAndPriceSettingsAction,
) => {
  switch (action.type) {
    case ActionTypes.STORE_PAYMENT_AND_PRICE_SETTINGS_DATA: {
      return {
        ...state,
        paymentAndPriceSettings: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default paymentAndPriceSettings
