import { ActionTypes } from 'constants@app'
import { IArchitecturesAction } from 'actions@app/architecture'
import { ServiceNameType } from 'lib@app/types'
import { IArchitecture } from 'model@app/architecture'

const initialState: Partial<IArchitecture> = {
  serviceName: ServiceNameType.HAPPYLOGUE,
}

const architecture = (
  state: Partial<IArchitecture> = initialState,
  action: IArchitecturesAction,
) => {
  switch (action.type) {
    case ActionTypes.FETCH_ARCHITECTURE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default architecture
