import { ActionTypes } from 'constants@app'
import { ITranslationsAction } from 'actions@app/translation'
import { ITranslation } from 'model@app/translation'

const initialState: Partial<ITranslation> = {
  logo: '',
}

const translation = (
  state: Partial<ITranslation> = initialState,
  action: ITranslationsAction,
) => {
  switch (action.type) {
    case ActionTypes.FETCH_TRANSLATION: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default translation
