import { ActionTypes } from 'constants@app'
import { IPurchaseHistoryAction } from 'actions@app/purchaseHistory'
import { IPurchase } from 'model@app/purchase'

export interface IPurchaseHistoryReducer {
  purchaseHistoryList?: IPurchase[]
}

const initialState: IPurchaseHistoryReducer = {
  purchaseHistoryList: [],
}

const preview = (
  state: IPurchaseHistoryReducer = initialState,
  action: IPurchaseHistoryAction,
) => {
  switch (action.type) {
    case ActionTypes.FETCH_PURCHASE_DATA: {
      return {
        ...state,
        purchaseHistoryList: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default preview
