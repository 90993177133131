/* eslint-disable no-shadow */
export enum ActionTypes {
  SESSION_SUCCESS_USER = 'SESSION_SUCCESS_USER',
  LOGOUT_SUCCESS_USER = 'LOGOUT_SUCCESS_USER',
  LOGOUT_ERROR_USER = 'LOGOUT_ERROR_USER',
  STORE_BOOK_ID = 'STORE_BOOK_ID',
  STORE_DURATION = 'STORE_DURATION',
  STORE_OPTIONS = 'STORE_OPTIONS',
  ALBUM_DATA_INFO = 'ALBUM_DATA_INFO',
  STORE_ALBUMS = 'STORE_ALBUMS',
  STORE_TITLE = 'STORE_TITLE',
  RESET_CREATE_BOOK_CONDITIONS = 'RESET_CREATE_BOOK_CONDITIONS',
  FETCH_BOOKS = 'FETCH_BOOKS',
  FETCH_DELETED_BOOKS = 'FETCH_DELETED_BOOKS',
  FETCH_BOOK = 'FETCH_BOOK',
  IS_EMPTY_CART = 'IS_EMPTY_CART',
  FETCH_CART = 'FETCH_CART',
  FETCH_COVER = 'FETCH_COVER',
  FETCH_PRODUCT = 'FETCH_PRODUCT',
  SAVE_SHIPPING_ADDRESS = 'SAVE_SHIPPING_ADDRESS',
  STORE_SHIPPING = 'STORE_SHIPPING',
  DELETE_ITEM = 'DELETE_ITEM',
  FETCH_PURCHASE_DATA = 'FETCH_PURCHASE_DATA',
  STORE_STRIPE_RESPONSE = 'STORE_STRIPE_RESPONSE',
  EDIT_USER_INFO = 'EDIT_USER_INFO',
  STORE_PAYMENT_AND_PRICE_SETTINGS_DATA = 'STORE_PAYMENT_AND_PRICE_SETTINGS_DATA',
  STORE_PAGES = 'STORE_PAGES',
  STORE_PRICE_RANGE = 'STORE_PRICE_RANGE',
  STORE_ITEMS_QUANTITY = 'STORE_ITEMS_QUANTITY',
  FETCH_ARCHITECTURE = 'FETCH_ARCHITECTURE',
  FETCH_TRANSLATION = 'FETCH_TRANSLATION',
  CLOSE_MESSAGETE_SNACBAR = 'CLOSE_MESSAGETE_SNACBAR',
  OPEN_MESSAGETE_SNACBAR = 'OPEN_MESSAGETE_SNACBAR',
  SET_IS_SIGNUP_NOT_COMPLETED = 'SET_IS_SIGNUP_NOT_COMPLETED',
  SESSION_STARTED_NO_USER = 'SESSION_STARTED_NO_USER',
}
