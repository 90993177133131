import { ActionType, createAction } from 'typesafe-actions'
import { ActionTypes } from 'constants@app'
import { ICart } from 'model@app/cart'
import { IProduct } from 'model@app/product'

export interface ICartData {
  cart: ICart
  products: IProduct[]
}

export const actions = {
  isEmptyCart: createAction(ActionTypes.IS_EMPTY_CART, (resolve): any => {
    return (cart: ICartData) => resolve(cart)
  }),
  fetchCartData: createAction(ActionTypes.FETCH_CART, (resolve): any => {
    return (cart: ICartData) => resolve(cart)
  }),
  fetchProductData: createAction(ActionTypes.FETCH_PRODUCT, (resolve): any => {
    return (product: ICartData) => resolve(product)
  }),
  deleteItem: createAction(ActionTypes.DELETE_ITEM, (resolve): any => {
    return (item: {
      cart?: ICart
      products?: IProduct[]
      result: boolean
      message: string
    }) => resolve(item)
  }),
  storeItemsQuantity: createAction(
    ActionTypes.STORE_ITEMS_QUANTITY,
    (resolve): any => {
      return (quantity: number) => resolve(quantity)
    },
  ),
}
export type ICartAction = ActionType<typeof actions>
