import { useMemo } from 'react'
import Redux, { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { reducers, IStore } from 'reducers@app/index'

let store: Redux.Store<IStore>

const initStore = (initialState?: IStore): Redux.Store<IStore> =>
  // TODO Deprecated symbol
  createStore(
    reducers,
    initialState,
    composeWithDevTools(applyMiddleware(thunkMiddleware)),
  )

export const initializeStore = (preloadedState?: IStore) => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    })
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export const useStore = (initialState?: IStore) =>
  useMemo(() => initializeStore(initialState), [initialState])

export type RootState = ReturnType<typeof store.getState>
