import { ActionTypes } from 'constants@app'
import { ICartAction } from 'actions@app/cart'
import { ICart } from 'model@app/cart'
import { IProduct } from 'model@app/product'

export interface ICartReducer {
  cart?: ICart
  products: IProduct[]
  isCartExist: boolean
  quantity?: number
}

const initialState: ICartReducer = {
  products: [],
  isCartExist: false,
}

const cart = (state: ICartReducer = initialState, action: ICartAction) => {
  switch (action.type) {
    case ActionTypes.IS_EMPTY_CART: {
      return {
        ...state,
        cart: {},
        products: [],
        isCartExist: false,
      }
    }
    case ActionTypes.FETCH_CART: {
      return {
        ...state,
        cart: action.payload.cart,
        isCartExist: true,
      }
    }
    case ActionTypes.FETCH_PRODUCT: {
      return {
        ...state,
        products: action.payload.products,
      }
    }
    case ActionTypes.STORE_ITEMS_QUANTITY: {
      return {
        ...state,
        quantity: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default cart
