import { ActionTypes } from 'constants@app'
import { IBookCoverAction } from 'actions@app/bookCover'

export interface IBookCoverReducer {
  coverPhoto?: string
  title?: string
}

const initialState: IBookCoverReducer = {
  coverPhoto: '',
  title: '',
}

const bookCover = (
  state: IBookCoverReducer = initialState,
  action: IBookCoverAction,
) => {
  switch (action.type) {
    case ActionTypes.FETCH_COVER: {
      return {
        ...state,
        coverPhoto: action.payload.coverPhoto,
        title: action.payload.title,
      }
    }
    default: {
      return state
    }
  }
}

export default bookCover
