import { ActionTypes } from 'constants@app'
import { IShippingAction } from 'actions@app/shipping'
import { IShipping } from 'model@app/shipping'

export interface IShippingReducer {
  shipping?: IShipping
  saveAddress?: boolean
}

const initialState: IShippingReducer = {
  saveAddress: false,
}

const shipping = (
  state: IShippingReducer = initialState,
  action: IShippingAction,
) => {
  switch (action.type) {
    case ActionTypes.STORE_SHIPPING: {
      return {
        ...state,
        shipping: { ...action.payload, saveAddress: state.saveAddress },
      }
    }

    case ActionTypes.SAVE_SHIPPING_ADDRESS: {
      return {
        ...state,
        saveAddress: action.payload,
      }
    }

    default:
      return state
  }
}

export default shipping
