import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSetRecoilState } from 'recoil'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { fetcher } from 'lib@app/fetcher'
import { actions } from 'actions@app/user'
import { snackbarState } from '@store/atoms/snackbar'

export const useSessionLogin = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const setSnackbar = useSetRecoilState(snackbarState)

  useEffect(() => {
    fetcher
      .post('/v1/user/session', {})
      .then(({ data }) => {
        if (!dispatch) return
        if (data.user) {
          dispatch(actions.sessionSuccess(data.user))
        } else if (Cookies.get('isSignupNotCompleted')) {
          dispatch(actions.setIsSignupNotCompleted())
        } else {
          dispatch(actions.setIsSessionStarted())
        }
      })
      .catch(() => {
        setSnackbar((prevState) => ({
          ...prevState,
          isOpen: true,
          message: 'エラーが発生しました',
        }))
      })
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dispatch, router.query?.code])
}
