import { createTheme, alpha } from '@mui/material/styles'
import theme from '../themes/theme'

export const defaultTheme = createTheme(theme, {
  components: {
    MuiButton: {
      defaultProps: { color: 'grey' },
      variants: [
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: theme.palette.getContrastText(theme.palette.grey[300]),
          },
        },
        {
          props: { variant: 'outlined', color: 'grey' },
          style: {
            color: theme.palette.text.primary,
            borderColor:
              theme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.23)'
                : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${theme.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor:
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity,
              ),
            },
          },
        },
        {
          props: { variant: 'text', color: 'grey' },
          style: {
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity,
              ),
            },
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: { variant: 'standard' },
    },
    MuiSelect: {
      defaultProps: { variant: 'standard' },
    },
  },
})
