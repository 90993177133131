import { createTheme, adaptV4Theme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        contrastText: '#fff',
        dark: '#33c9dc',
        light: '#33c9dc',
        main: '#00bcd4',
      },
      grey: {
        main: grey[300],
        dark: grey[400],
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
      background: {
        default: '#fafafa',
      },
    },
  }),
)

export default theme
