import { ActionTypes } from 'constants@app'
import { IPricingRangeAction } from 'actions@app/pricingRange'
import { IPricingRange } from 'model@app/pricingRange'

export interface IPricingRangeReducer {
  priceRange: IPricingRange[]
}

const initialState: IPricingRangeReducer = {
  priceRange: [],
}

const pricingRange = (
  state: IPricingRangeReducer = initialState,
  action: IPricingRangeAction,
) => {
  switch (action.type) {
    case ActionTypes.STORE_PRICE_RANGE: {
      return {
        ...state,
        priceRange: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default pricingRange
