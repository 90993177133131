import axios from 'axios'
import Cookies from 'js-cookie'

export const fetcher = {
  get: async (path: string, params: any = {}) =>
    axios.get(`${process.env.NEXT_PUBLIC_API_SERVER_URL}${path}`, {
      ...params,
      headers: {
        authorization: `Bearer ${Cookies.get('token')}`,
      },
    }),
  post: async (path: string, data: any = {}) =>
    axios.post(`${process.env.NEXT_PUBLIC_API_SERVER_URL}${path}`, data, {
      headers: {
        authorization: `Bearer ${Cookies.get('token')}`,
      },
    }),
  put: async (path: string, data: any = {}) =>
    axios.put(`${process.env.NEXT_PUBLIC_API_SERVER_URL}${path}`, data, {
      headers: {
        authorization: `Bearer ${Cookies.get('token')}`,
      },
    }),
  delete: async (path: string, params: any = {}) =>
    axios.delete(`${process.env.NEXT_PUBLIC_API_SERVER_URL}${path}`, {
      ...params,
      headers: {
        authorization: `Bearer ${Cookies.get('token')}`,
      },
    }),
} as const
