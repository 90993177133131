import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs'
import {Provider} from "react-redux";
import { RecoilRoot } from 'recoil';
import { useRouter } from 'next/router';
import { isNil  } from 'lodash';
import { ThemeProvider,  StyledEngineProvider } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { actions as architectureActions } from "actions@app/architecture";
import { actions as cartActions } from 'actions@app/cart';
import { actions as translationActions } from "actions@app/translation";
import { SnackbarView } from 'components@app/atoms/Snackbar/SnackbarView';
import { useSessionLogin } from 'hooks@app/useSessionLogin'
import { useStore } from '../store/configureStore'
import { fetcher } from 'lib@app/fetcher';
import { setupSentry } from 'lib@app/errorLog';
import { ServiceNameType } from 'lib@app/types';
import { defaultTheme } from 'styles@app/theme';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from 'lib@app/createEmotionCache';
import ReactGA from 'react-ga';

const styles = (theme) => ({
  '@global': {
    body: {
      margin: 0,
      backgroundColor: '#fafafa',
    },
    html: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
    },
  },
});

setupSentry()

let AppWrapper = (props) => props.children;
AppWrapper = withStyles(styles)(AppWrapper);

function SessionLogin() {
  useSessionLogin()

  return null
}

const clientSideEmotionCache = createEmotionCache();
const queryClient = new QueryClient()

export function MyApp({ Component, emotionCache = clientSideEmotionCache, pageProps }){
  const store = useStore(pageProps.initialReduxState)
  const router = useRouter()
  const [state, setState] = useState(store.getState())

  const updateState = () => {
    setState(store.getState())
  }
  store.subscribe(updateState)

  useEffect(() => {
    if(state?.user?.user?.userId) {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: state.user.user.userId })
      })
    }
  }, [state?.user?.user?.userId])

  useEffect(() => {
    if(state.user?.isSessionStarted && state.user?.isSignupNotCompleted && router.pathname !== '/signup') {
      router.push('/signup')
    }
  }, [state.user?.isSignupNotCompleted, state.user?.isSessionStarted])


  useEffect(() => {
    if (state.user?.isSessionStarted && state.user?.authenticated === false && state.user?.isSignupNotCompleted !== true &&
      router.pathname !== '/login' && router.pathname !== '/grant_permission' && router.pathname !== '/signup' && !router.pathname.includes('/cover/book_cover')) {
      router.push('/login')
    }

    if(state.user?.isSessionStarted && state.user?.authenticated === true && state.user?.isSignupNotCompleted !== true &&
      router.pathname === '/login') {
      router.push('/')
    }
  }, [state.user?.authenticated, state.user?.isSignupNotCompleted, state.user?.isSessionStarted, router.pathname])

  useEffect(() => {
    try {
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles) {
        jssStyles.parentElement.removeChild(jssStyles);
      }
      async function fetchCartItems() {
        const serviceName = process.env.NEXT_PUBLIC_SERVICE_NAME;

        if (![ServiceNameType.HAPPYLOGUE ,ServiceNameType.MINA].includes(serviceName)) {
          throw new Error('Invalid service name');
        }

        const architectureResponse = await fetcher.get('/v1/architecture/index', { params: { serviceName } })
        store.dispatch(architectureActions.fetchArchitecture(architectureResponse.data));
        const translationResponse = await fetcher.get('/v1/translation/index', { params: { serviceName } })
        store.dispatch(translationActions.fetchTranslation(translationResponse.data))

        const response = await fetcher.get('/v1/cart/fetch_cart_items');
        if(isNil(response.data.totalQuantity)) {
          store.dispatch(cartActions.storeItemsQuantity(0));
        } else {
          store.dispatch(cartActions.storeItemsQuantity(response.data.totalQuantity));
        }
      }
      fetchCartItems();
      ReactGA.initialize('UA-16732173-12');
      ReactGA.pageview(router.asPath);
    } catch (err) {
      //console.log(err);
    }
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={defaultTheme}>
          <Provider store={store}>
            <RecoilRoot>
              <SessionLogin />
              <QueryClientProvider client={queryClient}>
                <AppWrapper>
                  <Component {...pageProps} />
                  <SnackbarView />
                </AppWrapper>
              </QueryClientProvider>
            </RecoilRoot>
          </Provider>
        </ThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  );

}

export default MyApp;
