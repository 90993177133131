import { ActionType, createAction } from 'typesafe-actions'
import { ActionTypes } from 'constants@app'
import { ITranslation } from 'model@app/translation'

export const actions = {
  fetchTranslation: createAction(
    ActionTypes.FETCH_TRANSLATION,
    (resolve): any => {
      return (translation: ITranslation) => resolve(translation)
    },
  ),
}

export type ITranslationsAction = ActionType<typeof actions>
