import { ActionTypes } from 'constants@app'
import { IMypageAction } from 'actions@app/myPage'
import { IBook } from 'model@app/book'
import { IBookMeta } from 'model@app/bookMeta'

export interface IMypageReducer {
  books: IBook[]
  failedBooks: IBookMeta[]
}

const initialState: IMypageReducer = {
  books: [],
  failedBooks: [],
}

const myPage = (
  state: IMypageReducer = initialState,
  action: IMypageAction,
) => {
  switch (action.type) {
    case ActionTypes.FETCH_BOOKS: {
      return {
        ...state,
        books: action.payload,
      }
    }

    case ActionTypes.FETCH_DELETED_BOOKS: {
      return {
        ...state,
        failedBooks: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default myPage
