import { ActionType, createAction } from 'typesafe-actions'
import { ActionTypes } from 'constants@app'
import { IArchitecture } from 'model@app/architecture'

export const actions = {
  fetchArchitecture: createAction(
    ActionTypes.FETCH_ARCHITECTURE,
    (resolve): any => {
      return (architecture: IArchitecture) => resolve(architecture)
    },
  ),
}

export type IArchitecturesAction = ActionType<typeof actions>
