import moment from 'moment'
import { ActionTypes } from 'constants@app'
import { ICreateBookAction, IAlbumValue } from 'actions@app/createBook'
import { BookLayoutTypes } from 'lib@app/types'

interface IData {
  height: number
  width: number
  url: string
}

export interface IPage {
  id: string
  name: string
  picture: {
    data: IData
  }
}

export interface ICreateBookReducer {
  bookId: string | null
  duration: { startDate: string; endDate: string }
  albums: IAlbumValue
  title: string
  bookLayoutType: BookLayoutTypes
}

const initialState: ICreateBookReducer = {
  bookId: null,
  duration: {
    startDate: moment('2021-01-01').format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD'),
  },
  albums: {
    albumTitles: {},
    albumDescriptions: {},
    albumIds: [],
    selectedAlbumIds: [],
  },
  title: '２０１９年の思い出',
  bookLayoutType: BookLayoutTypes.SINGLE_SIDED,
}

const createBook = (
  state: ICreateBookReducer = initialState,
  action: ICreateBookAction,
) => {
  switch (action.type) {
    case ActionTypes.STORE_BOOK_ID: {
      return {
        ...state,
        bookId: action.payload,
      }
    }

    case ActionTypes.STORE_DURATION: {
      return {
        ...state,
        duration: action.payload,
      }
    }

    case ActionTypes.STORE_OPTIONS: {
      return {
        ...state,
        bookLayoutType: action.payload.bookLayoutType,
      }
    }

    case ActionTypes.STORE_TITLE: {
      return {
        ...state,
        title: action.payload,
      }
    }

    case ActionTypes.ALBUM_DATA_INFO: {
      return {
        ...state,
        albums: action.payload,
      }
    }

    case ActionTypes.STORE_ALBUMS: {
      return {
        ...state,
        albums: action.payload,
      }
    }

    case ActionTypes.STORE_PAGES: {
      return {
        ...state,
        pages: action.payload,
      }
    }

    case ActionTypes.RESET_CREATE_BOOK_CONDITIONS: {
      return {
        ...state,
        ...initialState,
      }
    }
    default: {
      return state
    }
  }
}

export default createBook
