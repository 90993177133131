import { ActionTypes } from 'constants@app'
import { IUserAction } from 'actions@app/user'
import { IUser } from 'model@app/user'

export interface IUserReducer {
  authenticated: boolean
  isSignupNotCompleted: boolean
  message?: string
  user?: IUser | null
  isSessionStarted: boolean
}

const initialState: IUserReducer = {
  authenticated: false,
  isSignupNotCompleted: false,
  message: '',
  isSessionStarted: false,
}

const user = (state: IUserReducer = initialState, action: IUserAction) => {
  switch (action.type) {
    case ActionTypes.SESSION_SUCCESS_USER: {
      return {
        ...state,
        authenticated: true,
        isSignupNotCompleted: false,
        user: action.payload,
        isSessionStarted: true,
      }
    }

    case ActionTypes.SESSION_STARTED_NO_USER: {
      return {
        ...state,
        authenticated: false,
        isSignupNotCompleted: false,
        isSessionStarted: true,
      }
    }

    case ActionTypes.SET_IS_SIGNUP_NOT_COMPLETED: {
      return {
        ...state,
        authenticated: false,
        isSignupNotCompleted: true,
        isSessionStarted: true,
      }
    }

    case ActionTypes.LOGOUT_SUCCESS_USER: {
      return {
        ...state,
        authenticated: false,
        user: null,
      }
    }
    case ActionTypes.LOGOUT_ERROR_USER: {
      return {
        ...state,
        authenticated: true,
      }
    }

    default: {
      return state
    }
  }
}

export default user
