import { combineReducers } from 'redux'
import { IArchitecture } from 'model@app/architecture'
import { ITranslation } from 'model@app/translation'
import architecture from './architecture'
import bookCover, { IBookCoverReducer } from './bookCover'
import cart, { ICartReducer } from './cart'
import createBook, { ICreateBookReducer } from './createBook'
import myPage, { IMypageReducer } from './myPage'
import paymentAndPriceSettings, {
  IPaymentAndPriceSettingsReducer,
} from './paymentAndPriceSettings'
import purchaseHistory, { IPurchaseHistoryReducer } from './purchaseHistory'
import preview, { IPreviewReducer } from './preview'
import settings, { ISettingsReducer } from './settings'
import shipping, { IShippingReducer } from './shipping'
import user, { IUserReducer } from './user'
import pricingRange, { IPricingRangeReducer } from './pricingRange'
import translation from './translation'

export interface IStore {
  user: IUserReducer
  architecture: IArchitecture
  bookCover: IBookCoverReducer
  createBook: ICreateBookReducer
  myPage: IMypageReducer
  cart: ICartReducer
  preview: IPreviewReducer
  settings: ISettingsReducer
  shipping: IShippingReducer
  purchaseHistory: IPurchaseHistoryReducer
  paymentAndPriceSettings: IPaymentAndPriceSettingsReducer
  pricingRange: IPricingRangeReducer
  translation: ITranslation | null
}

export const reducers = combineReducers<any, any>({
  user,
  architecture,
  bookCover,
  createBook,
  myPage,
  cart,
  preview,
  settings,
  shipping,
  purchaseHistory,
  paymentAndPriceSettings,
  pricingRange,
  translation,
})
