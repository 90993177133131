import { ActionType, createAction } from 'typesafe-actions'
import { ActionTypes } from 'constants@app'
import { IUser } from 'model@app/user'

export const actions = {
  sessionSuccess: createAction(
    ActionTypes.SESSION_SUCCESS_USER,
    (resolve): any => {
      return (userData: IUser) => resolve(userData)
    },
  ),
  setIsSessionStarted: createAction(ActionTypes.SESSION_STARTED_NO_USER),
  setIsSignupNotCompleted: createAction(
    ActionTypes.SET_IS_SIGNUP_NOT_COMPLETED,
  ),
  logoutSuccess: createAction(ActionTypes.LOGOUT_SUCCESS_USER),
  logoutError: createAction(ActionTypes.LOGOUT_ERROR_USER),
}

export type IUserAction = ActionType<typeof actions>
