import { ActionTypes } from 'constants@app'
import { IPreviewAction } from 'actions@app/preview'
import { IBook } from 'model@app/book'
import { IFeed } from 'model@app/feed'

export interface IPreviewReducer {
  book?: IBook
  feeds: IFeed[]
  editLock?: boolean
}

const initialState: IPreviewReducer = {
  feeds: [],
}

const preview = (
  state: IPreviewReducer = initialState,
  action: IPreviewAction,
) => {
  switch (action.type) {
    case ActionTypes.FETCH_BOOK: {
      return {
        ...state,
        book: action.payload.book,
        feeds: action.payload.feeds,
        editLock: action.payload.editLock,
      }
    }

    default: {
      return state
    }
  }
}

export default preview
